import { prio } from './prio';
import { lessThanEight } from './less-than-eight';
import { lessThanTwelve } from './less-than-twelve';
import { lessThanFifteen } from './less-than-fifteen';
import { lessThanThirty } from './less-than-thirty';

export const felyxPresets = {
  prio,
  lessThanEight,
  lessThanTwelve,
  lessThanFifteen,
  lessThanThirty,
};
