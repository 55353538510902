import { VehiclesFiltersExpression } from '../is-preset-match';

export const lessThanEight: VehiclesFiltersExpression = {
  or: [
    {
      and: [
        { batteryRange: [0, 8] },
        { containsAnyBlockingTasks: false },
        { isInALocation: false },
      ],
    },
    {
      and: [
        {
          or: [
            {
              isLocationIdMatch: 'a74ef12a-f8c5-44ff-82d7-9ae46e1b23a5',
            },
            {
              isLocationIdMatch: '06f33ca0-7b5f-48ae-86f2-2c09bcebff8d',
            },
          ],
        },
      ],
    },
    {
      and: [
        { isInALocation: false },
        { containsAnyBlockingTasks: true },
        {
          noOtherTasksThan: [
            {
              title: 'B - V - NL - Helmet 03 - Helmet missing',
            },
            {
              title: 'B - V - NL - Helmet 04 - Helmets missing (Both)',
            },
            {
              title: 'B - V - NL - Helmet 05 - Adittional helmet',
            },
            {
              title: 'B - V - NL - Helmet 06 - Helmet broken',
            },
            {
              title: 'B - V - NL - Helmet 07 - Helmet stolen',
            },
            {
              title: 'B - V - NL - Helmet 08 - Helmets broken (Both)',
            },
            {
              title: 'B - V - NL - Mirror 01 - Mirrors broken or missing',
            },
            {
              title: 'B - V - NL - Mirror 03 - Left mirror broken or missing',
            },
            {
              title: 'B - V - NL - Accessories 01 - Hairnets missing',
            },
            {
              title: 'B - V - NL - Accessories 02 - Topcase innerliner missing',
            },
            {
              title:
                'B - V - NL - Accessories 03 - Topcase innerliner dirty/mouly',
            },
          ],
        },
      ],
    },
  ],
};
