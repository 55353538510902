import { Preset } from '../vehicles-filters-form';

import { VehiclesFiltersExpression } from './is-preset-match';
import { barcelonaPresets } from './barcelona';
import { madridPresets } from './madrid';
import { globalPresets } from './global';
import { milanPresets } from './milan';
import { felyxPresets } from './felyx';

export const presets: { [key in Preset]: VehiclesFiltersExpression } = {
  'barcelona.swapper': barcelonaPresets.swapper,
  'barcelona.swapper-wqnr': barcelonaPresets.swapperWqnr,
  'madrid.swapper': madridPresets.swapper,
  'global.swapper': globalPresets.swapper,
  'milan.swapper': milanPresets.swapper,
  'felyx.prio': felyxPresets.prio,
  'felyx.less-than-eight': felyxPresets.lessThanEight,
  'felyx.less-than-twelve': felyxPresets.lessThanTwelve,
  'felyx.less-than-fifteen': felyxPresets.lessThanFifteen,
  'felyx.less-than-thirty': felyxPresets.lessThanThirty,
};
