import {
  Model,
  Telematics,
  OperationalCondition,
  ThreeStateValue,
} from '@cooltra/api';
import { BatteryChargeLevel } from '@cooltra/vehicle-utils';

type OperationalConditionPrefix = '' | '!';

export type ExtendedOperationalCondition =
  `${OperationalConditionPrefix}${OperationalCondition}`;

export type Preset =
  | 'global.swapper'
  | 'madrid.swapper'
  | 'barcelona.swapper'
  | 'barcelona.swapper-wqnr'
  | 'milan.swapper'
  | 'felyx.prio'
  | 'felyx.less-than-eight'
  | 'felyx.less-than-twelve'
  | 'felyx.less-than-fifteen'
  | 'felyx.less-than-thirty';

export type IdleFrom =
  | '1-20h'
  | '20-24h'
  | '1-2d'
  | '2-3d'
  | '3-4d'
  | '4-5d'
  | '5-10d'
  | '+10d';

export type VehiclesFiltersFormValues = {
  preset: Preset | undefined;
  model: Model[];
  telematics: Telematics[];
  location: string[];
  tagId: string[];
  taskOperatorId: string[];
  taskOperatorGroupId: string[];
  operationalCondition: ExtendedOperationalCondition[];
  batteryChargeLevel: BatteryChargeLevel[];
  idleFrom: IdleFrom[];
  unbalanced: ThreeStateValue;
};

export const vehiclesFiltersInitialValues: VehiclesFiltersFormValues = {
  preset: undefined,
  model: [],
  telematics: [],
  location: [],
  tagId: [],
  taskOperatorId: [],
  taskOperatorGroupId: [],
  operationalCondition: [],
  batteryChargeLevel: [],
  idleFrom: [],
  unbalanced: '',
};
