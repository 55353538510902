import { System } from '@cooltra/auth-api';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useVehiclesFilters,
  vehiclesFiltersInitialValues,
  Preset,
} from '~/libs/vehicles-filters';
import { useUserSystems } from '~/hooks';

import messages from './messages';
import { OutlineButton } from './OutlineButton';

export const presetsByHomeSystem: { [key in System]: Preset[] } = {
  Barcelona: ['barcelona.swapper', 'barcelona.swapper-wqnr'],
  Berlin: ['global.swapper'],
  Munich: ['global.swapper'],
  Lisbon: ['global.swapper'],
  Madrid: ['madrid.swapper'],
  Malaga: ['global.swapper'],
  Mallorca: ['global.swapper'],
  Milan: ['milan.swapper'],
  Paris: [],
  Rome: ['global.swapper'],
  Valencia: ['global.swapper'],
  Zurich: ['global.swapper'],
  Turin: ['global.swapper'],
  Seville: ['global.swapper'],
  Amsterdam: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Breda: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Brussels: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Den_bosch: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  The_hague: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Enschede: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Groningen: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Haarlem: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Nijmegen: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Rotterdam: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Tilburg: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Zwolle: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Hilversum: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Antwerp: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Arnhem: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Eindhoven: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
  Delft: [
    'felyx.prio',
    'felyx.less-than-eight',
    'felyx.less-than-twelve',
    'felyx.less-than-fifteen',
    'felyx.less-than-thirty',
  ],
};

const presetsLabels: { [key in Preset]: string } = {
  'barcelona.swapper': 'Swapper',
  'barcelona.swapper-wqnr': 'Swapper (WQNR)',
  'madrid.swapper': 'Swapper',
  'global.swapper': 'Swapper',
  'milan.swapper': 'Swapper',
  'felyx.prio': 'Prio',
  'felyx.less-than-eight': '< 8%',
  'felyx.less-than-twelve': '< 12%',
  'felyx.less-than-fifteen': '< 15%',
  'felyx.less-than-thirty': '< 30%',
};

export type PresetsProps = {
  rightContent: ReactNode;
};

export const Presets = ({ rightContent }: PresetsProps) => {
  const systems = useUserSystems();

  const { values, setValues } = useVehiclesFilters();

  const resetPresets = () => setValues(vehiclesFiltersInitialValues);

  const isPresetActive = (preset: Preset) => values.preset === preset;

  const setPreset = (preset: Preset) => () => {
    const { preset: initialPreset, ...initialValues } =
      vehiclesFiltersInitialValues;
    setValues({ preset, ...initialValues });
  };

  // use the first home system because should be the same for the HomeSystems of the role
  const presets = presetsByHomeSystem[systems[0]];

  if (!presets.length) {
    return (
      <div className="border-b border-neutral-100">
        <div className="px-4 flex items-center justify-end mb-1 h-10">
          {rightContent}
        </div>
      </div>
    );
  }

  return (
    <div className="border-b border-neutral-100">
      <div className="px-4 flex items-center justify-between mb-1 h-10">
        <h6 className="text-sm font-semibold">
          <FormattedMessage {...messages.presets} />
        </h6>
        {rightContent}
      </div>
      <ul className="px-4 pt-1 pb-6 overflow-x-auto flex gap-4 snap-x">
        {presets.map((preset) => {
          const isActive = isPresetActive(preset);
          return (
            <OutlineButton
              data-testid={isActive && `${preset}_PRESET_ACTIVE`}
              isActive={isActive}
              key={preset}
              onClick={isActive ? resetPresets : setPreset(preset)}
              className="scroll-mx-4 snap-start"
            >
              {presetsLabels[preset]}
            </OutlineButton>
          );
        })}
      </ul>
    </div>
  );
};
